.container-tracking {
  height: 100vh !important;
  min-height: 100vh !important;
  width: 100% !important;
  min-width: 100% !important;
}

.container-mapa {
  height: calc(100vh - 83px - 48px) !important;
  min-height: calc(100vh - 83px - 48px) !important;
  width: 100% !important;
  min-width: 100% !important;
}

.container-mapa-time {
  height: calc(100vh - 48px) !important;
  min-height: calc(100vh - 48px) !important;
  width: 100% !important;
  min-width: 100% !important;
}

@media (min-width: 0px) and (max-width: 600px) {
  .container-mapa {
    height: calc(100vh - 83px - 58.59px) !important;
    min-height: calc(100vh - 83px - 58.59px) !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .container-mapa-time {
    height: calc(100vh - 58.59px) !important;
    min-height: calc(100vh - 58.59px) !important;
    width: 100% !important;
    min-width: 100% !important;
  }
}

.img-customer {
  height: 25px;
}

.img-logo-rayo {
  height: 22px;
}

@media (min-width: 0px) and (max-width: 600px) {
  .img-customer {
    height: 20px;
  }

  .img-logo-rayo {
    height: 17px;
  }

  .container-mapa-time .col-md-4,
  .container-mapa-time .col-md-8,
  .container-mapa .col-md-4,
  .container-mapa .col-md-8 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.container-informacion {
  position: absolute;
  bottom: 30px;
  margin-left: 100px;
  margin-top: 100px;
  width: calc(100% - 200px);
  background-color: #f2f2f2;
}

.container-hora {
  position: absolute;
  bottom: calc(100px + 3.5rem);
  right: 100px;
  background-color: #f2f2f2;
}

.img-tracking {
  height: 60px;
  width: 60px;
  border-radius: 5px;
}

.estado-track {
  top: 75px;
  left: 70px;
}

.container-hora p,
.container-informacion p {
  font-family: "Roboto-Regular";
  font-size: 12px;
  color: #545454;
  margin-bottom: 0px;
  line-height: 1.4;
}

.border-container-informacion {
  border-color: #cccccc !important;
}

.container-gt {
  background-color: #e3e3e3;
  border-radius: 5px;
  padding: 0.8rem;
  height: 100px;
  overflow: auto;
  font-family: "Roboto-Regular";
  font-size: 12px;
}

.txt-bold {
  font-family: "Exo2-Bold" !important;
  text-transform: uppercase;
}

.container-estimado {
  max-width: 300px;
  width: 100%;
}

@media (min-width: 0px) and (max-width: 992px) {
  .container-informacion {
    position: absolute;
    bottom: 15px;
    margin-left: 15px;
    margin-top: 15px;
    width: calc(100% - 30px);
  }

  .container-hora {
    position: absolute;
    bottom: calc(188px + 3rem);
    right: 30px;
    background-color: #f2f2f2;
  }

  .img-tracking {
    height: 40px;
    width: 40px;
  }

  .estado-track {
    top: 34px;
    left: 49px;
  }

  .tracking-rayo {
    margin-bottom: 1rem !important;
    margin-top: 0.5rem !important;
  }

  .container-gt {
    margin-bottom: 0.5rem !important;
  }
}

.MuiSvgIcon-root.text-white {
  color: #ffffff !important;
}

.boton-whatsapp {
  position: absolute !important;
  top: 50px;
  left: -25px;
}

/* FUENTES */
@font-face {
  font-family: "Exo2-Regular";
  src: url(/static/media/Exo2-Regular.b5f984d1.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Bold";
  src: url(/static/media/Exo2-Bold.5c6e2852.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Exo2-Black";
  src: url(/static/media/Exo2-Black.b5a82f67.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(/static/media/Roboto-Regular.11eabca2.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(/static/media/Roboto-Bold.e07df86c.ttf);
  font-display: swap;
}

/*-----------------------------------------------  NEW STYLES ----------------------------------------------------*/

table {
  /* border-spacing: 0px !important; */
  border-spacing: 0 8px !important;
}

.table-white.MuiTableContainer-root {
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
}

tr {
  height: 50px !important;
}

/* .MuiInputBase-input {
  height: 1em !important;
} */

/*-----------------------------------------------  NEW STYLES ----------------------------------------------------*/

/* .MuiOutlinedInput-root fieldset {
  border: 2px solid #1a11d1 !important;
  border-radius: 4px;
} */

.MuiButton-root {
  text-transform: none !important;
  text-transform: initial !important;
}

.lg-modal {
  width: calc(100% - 30px);
  max-width: 1000px;
  margin-left: 15px;
  margin-right: 15px;
}

.md-modal {
  width: calc(100% - 30px);
  max-width: 800px;
  margin-left: 15px;
  margin-right: 15px;
}

.sm-modal {
  width: calc(100% - 30px);
  max-width: 600px;
  margin-left: 15px;
  margin-right: 15px;
}

/*-----------------------------------------------  NEW STYLES ----------------------------------------------------*/

.h-100 {
  height: 100% !important;
}

.p-relative {
  position: relative !important;
}

h1,
h2,
h3,
h4,
h5,
strong,
b {
  font-family: "Exo2-Bold" !important;
}

.text-exo-light,
#outlined-search-label,
.MuiInputBase-input,
input,
.MuiFormLabel-root {
  font-family: "Roboto-Regular" !important;
}

.MuiButton-root.text-roboto-regular,
.text-roboto-regular {
  font-family: "Roboto-Regular" !important;
}

.text-exo-bold {
  font-family: "Roboto-Bold";
}

.text-exo-black {
  font-family: "Exo2-Black";
}

/* COLORES DE FONDO */
.back-dark-red,
.back-light-blue {
  background-color: #4545f4 !important;
}

.blue-selected {
  background-color: #e4e9fa !important;
}

.back-lightest-blue {
  background-color: #9999ff !important;
}

.back-medium-red {
  background-color: #b20000 !important;
}

.back-red,
.dot-haFallado,
.MuiTab-textColorInherit {
  background-color: #bf0811 !important;
}

.txt-ha-fallado {
  color: #bf0811 !important;
}

.back-dark-grey {
  background-color: #1a11d1 !important;
}

.bg-primary-light {
  background-color: #5240f2 !important;
}

.bg-primary {
  background-color: #311def !important;
}

.bg-primary-dark {
  background-color: #1f0dbf !important;
}

.bg-secondary-light {
  background-color: #5fcbec !important;
}

.bg-secondary {
  background-color: #1cb5e5 !important;
}

.bg-secondary-dark {
  background-color: #137fa0 !important;
}

.back-medium-grey,
.dot-no-asignado {
  background-color: #858585 !important;
  opacity: 1 !important;
}

.txt-no-asignado {
  color: #858585 !important;
}

.back-medium-grey:disabled {
  background-color: #858585 !important;
  opacity: 1 !important;
}

.back-grey-input {
  background-color: #cfcdcd !important;
}

.back-grey {
  background-color: #e6e6e6 !important;
}

.back-super-light-grey {
  background-color: #fcfcfc !important;
}

.back-light-grey {
  background-color: #e5e5e5 !important;
}

.back-light-grey-2 {
  background-color: #efefef !important;
}

.back-yellow {
  background-color: #f7ac06 !important;
}

.back-sky-blue {
  background-color: #1baefc !important;
}

.back-mega-dark-grey {
  background-color: #545454 !important;
}

.back-mega-grey {
  background-color: #cfcdcd !important;
}

.back-plomo {
  background-color: #f2f2f2 !important;
}

.back-hiper-light-grey {
  background-color: #e3e3e3 !important;
}

.back-transparent {
  background-color: transparent !important;
}

.dot-exitoso,
.activo {
  background-color: #1fc600 !important;
}

.txt-exitoso {
  color: #1fc600 !important;
}

.dot-asignado {
  background-color: #f25c22 !important;
}

.dot-aceptado {
  background-color: #d60277 !important;
}

.dot-devuelto {
  background-color: #ffd518 !important;
}

.txt-aceptado {
  color: #d60277 !important;
}

.dot-iniciado {
  background-color: #1b84bf !important;
}

.dot-en-progreso {
  background-color: #0a4d73 !important;
}

.txt-en-progreso {
  color: #0a4d73 !important;
}

.dot-rechazado {
  background-color: #cfcdcd !important;
}

.txt-rechazado {
  color: #cfcdcd !important;
}

.dot-cancelado {
  background-color: #990000 !important;
}

.txt-cancelado {
  color: #990000 !important;
}

.inactivo {
  background-color: #9f9f9f !important;
}

.bg-red {
  background-color: rgb(239, 2, 2);
}

.bg-purple {
  background-color: #b555d2 !important;
}

/* COLORES DE TEXTO */
.color-medium-red {
  color: #b20000 !important;
}

.color-dark-grey {
  color: #242422 !important;
}

.color-blue {
  color: #1b84bf !important;
}

.color-purple {
  color: #b555d2 !important;
}

.color-orange {
  color: #f25c22 !important;
}

.color-yellow {
  color: #f7ac06 !important;
}

.color-devuelto {
  color: #ffd518 !important;
}

.color-light-grey {
  color: #9f9f9f !important;
}

.color-plomo {
  color: #f2f2f2 !important;
}

.color-mega-dark-grey {
  color: #545454 !important;
}

.text-blue {
  color: #311def !important;
}

/* COLUMNAS PERSONALIZADAS */
.col-custom-95 {
  flex: 0 0 95% !important;
  width: 95% !important;
  position: relative;
}
.col-custom-5 {
  flex: 0 0 5% !important;
  width: 5% !important;
  position: relative;
}

.col-custom-90 {
  flex: 0 0 90% !important;
  width: 90% !important;
  position: relative;
}

.col-custom-80 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(79% - 0.6666666666666%);
  width: calc(79% - 0.6666666666666%) !important;
  position: relative;
}

.col-custom-10 {
  flex: 0 0 10% !important;
  width: 10% !important;
  position: relative;
}

.pr-05 {
  padding-right: 5px !important;
}

a {
  text-decoration: none !important;
}

input .form-control:disabled {
  background-color: #797979 !important;
}

.light-disabled {
  background-color: #d9d9d9 !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

button::-moz-focus-inner {
  border: 0 !important;
}

button {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #343434 !important;
}

label.filebutton {
  height: 36px;
  width: 55px;
  padding-left: 6px;
  padding-right: 6px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}

label.filebutton2 {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity = 0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
}

table {
  /* border-collapse: separate !important; */
  /* border-spacing: 0 8px !important; */
}

.template-input:-webkit-autofill,
.template-input:-webkit-autofill:hover,
.template-input:-webkit-autofill:focus,
.template-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  font-family: "Roboto-Regular" !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 0px;
  margin-bottom: 0px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #858585;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #858585;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c !important;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8c8c8c !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8c8c8c !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

/*Colores*/
.color-black {
  color: #242422 !important;
}

.color-dark-grey {
  color: #545454 !important;
}

.color-grey {
  color: #9f9f9f !important;
}

.br-4 {
  border-radius: 4px !important;
}

.login-alto {
  height: 100vh;
}

.center {
  justify-content: center;
  align-items: center;
}

.icon-eye {
  width: 70%;
}

.icon-search {
  width: 40%;
}

.icon-button {
  width: 20%;
}

.icon-accion {
  width: 25px;
}

/* Menú */
.bar-rayo {
  height: 100px !important;
  background-color: #bf0811 !important;
}

.icon-button-menu {
  width: 27px !important;
  height: 27px !important;
  top: 22px !important;
  left: 80px !important;
}

.rayo-logo-header {
  top: 26px !important;
  left: 152px !important;
  width: 206px !important;
  height: 53px !important;
  opacity: 1 !important;
}

.button-red {
  background-color: #990000 !important;
}

.button-black {
  background-color: #242422 !important;
}

.button-header {
  width: 100px !important;
  height: 35px !important;
}

.MuiButton-root.btn-cliente {
  min-width: 100px !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}

.text-button-header {
  color: #f2f2f2;
  font-size: 12px;
  font-family: "Roboto-Regular";
}

.form-control.input-id-grupo {
  background-color: #dad8d8 !important;
  text-align: center !important;
  border-radius: 5px !important;
  font-family: "Roboto-Regular";
  font-size: 24px;
  height: 40px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.iconos_menu {
  width: 25px !important;
  height: 25px !important;
}

.title-text {
  font-family: "Exo2-Bold";
  color: #343434;
  flex-grow: 1;
}

.line-height-1 {
  line-height: 1 !important;
}

.title-text-white {
  font-family: "Exo2-Bold";
  color: #ffffff !important;
  flex-grow: 1;
}

.title-text-blue {
  font-family: "Exo2-Bold";
  color: #311def !important;
  flex-grow: 1;
}

/* Mantenedor */
.icono-logo-rayo {
  width: 58px;
  height: 54px !important;
}

/* Mantenedor */
.icono-logo-lista {
  width: 90px;
  height: 90px !important;
}

.thead-gray {
  background-color: #9f9f9f;
}

.text-title-table {
  font-family: "Roboto-Regular";
  color: #f2f2f2;
  font-size: 22px;
}

.text-td-table {
  font-family: "Roboto-Regular";
  color: #9f9f9f;
  font-size: 20px;
}

.custom-select {
  font-family: "Roboto-Regular";
  color: #9f9f9f;
  border: none !important;
  font-size: 0.85rem !important;
  background-color: #f2f2f2 !important;
}

.custom-select:focus {
  box-shadow: none !important;
}

.MuiAccordion-root > .MuiCollapse-container {
  background-color: #f2f2f2;
}

.modal-body > .form-row > .form-group > .input-group > .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
}

.modal-body > .form-row > .form-group > .input-group > div > .input-group-text {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
}

.modal-body > .form-row > .form-group > .form-control {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.modal-body > .form-row > .form-group > .form-control:disabled {
  border: none !important;
  background-color: #9f9f9f !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > div
  > .input-group-text {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: #dad8d8 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > .form-control {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: none !important;
  background-color: #dad8d8 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control,
.MuiAccordionDetails-root > .form-tareas > .form-group > .form-control,
.MuiAccordionDetails-root > .form-tareas > .form-group > div > .form-control {
  border: none !important;
  background-color: #dad8d8 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control,
.MuiAccordionDetails-root > .form-tareas > .form-group > .custom-select,
.MuiAccordionDetails-root > .form-tareas > .form-group > div > .form-control {
  border: none !important;
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-group
  > .custom-select:disabled,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:disabled {
  -webkit-box-shadow: 0 0 0 30px #a8a4a4 inset !important;
  background-color: #a8a4a4 !important;
  color: #000000 !important;
}

.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > .form-control:focus,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .input-group
  > .form-control:-webkit-autofill,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-group
  > .form-control:-webkit-autofill,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill:hover,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill:focus,
.MuiAccordionDetails-root
  > .form-tareas
  > .form-row
  > .form-group
  > .form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #dad8d8 inset !important;
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.order-id-input:focus,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill:hover,
.order-id-input:-webkit-autofill:focus,
.order-id-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #dad8d8 inset !important;
  background-color: #dad8d8 !important;
}

.order-id-input:focus,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill,
.order-id-input:-webkit-autofill:hover,
.order-id-input:-webkit-autofill:focus,
.order-id-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #cfcdcd inset !important;
  background-color: #cfcdcd !important;
}

.form-control.template-input {
  background-color: #dad8d8 !important;
  color: #000000 !important;
}

.form-control-login {
  border: 1px solid #242422 !important;
}

.input-buscar {
  border: none !important;
  background-color: #efefef !important;
  border-radius: 0px !important;
}

.input-group-append.append-crear {
  width: 38px !important;
  background-color: #efefef !important;
}

.texto-form {
  font-family: "Roboto-Regular";
  color: #ffffff;
  margin-bottom: 0.25rem !important;
}

.texto-form-dark {
  font-family: "Roboto-Regular";
  color: #242422;
  margin-bottom: 0.25rem !important;
}

.texto-form-red {
  font-family: "Roboto-Regular";
  color: #bf0811;
  margin-bottom: 0.25rem !important;
}

.texto-form-red-light {
  font-family: "Roboto-Regular";
  color: #f8545c;
  margin-bottom: 0.25rem !important;
}

.btn-primary-rayo {
  background-color: #1cb5e5;
  color: #f2f2f2;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary-rayo {
  background-color: #4545f4 !important;
  color: #f2f2f2;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary {
  background-color: transparent !important;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  color: #000000 !important;
  border: none !important;
}

.btn-secondary-white {
  background-color: transparent !important;
  font-family: "Exo2-Bold";
  font-size: 0.85rem;
  color: #fff;
  border: none !important;
}

.btn-acciones {
  background-color: #e6e6e6;
  color: black;
  border-color: #e6e6e6;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0;
}

.border-th {
  border: 2px solid #f1f1f1 !important;
}

.dropdown-item {
  border: 2px solid black !important;
  display: flex !important;
  justify-content: left !important;
  align-items: left !important;
  text-align: left !important;
}

/*SweetAlert*/
.imageSweetAlert {
  background-image: url(https://storage.googleapis.com/fleet-manager-env.appspot.com/Assets/icono-rayoapp-red.svg) !important;
  width: 100px;
  height: 100px;
}

.titleSweetAlert {
  font-family: "Exo2-Bold" !important;
  color: #1a11d1 !important;
}

.textSweetAlert {
  font-family: "Roboto-Regular" !important;
  color: #343434 !important;
}

.buttonSweetAlert {
  color: #fff !important;
  background-color: #1cb5e5 !important;
  font-family: "Exo2-Bold" !important;
}

.cancelButtonSweetAlert {
  color: #fff !important;
  background-color: #242422 !important;
  font-family: "Exo2-Bold" !important;
}

.p-1-5 {
  padding: 0.3rem;
}

/* ESTILOS TABLA*/

.MuiTableHead-root tr {
  border-radius: 10px !important;
  height: 50px !important;
  margin-bottom: 10px;
}

.MuiTableHead-root tr th {
  /* background-color: #9f9f9f !important;
  color: white; */
  font-family: "Roboto-Regular";
  /* text-align: center; */
  /* padding-top: 5px;
  padding-bottom: 5px; */
  /* min-height: 80px; */
  margin-bottom: 10px !important;
}

.MuiTableBody-root tr td {
  /* background-color: #e6e6e6 !important;
  color: #545454; */
  font-family: "Roboto-Regular";
  /* text-align: center; */
  /* padding-top: 5px;
  padding-bottom: 5px; */
  min-height: 80px;
}

.MuiTableRow-root {
  border-radius: 10px !important;
}

.MuiTable-root.MuiTable-stickyHeader {
  border-top-left-radius: 10px !important;
}

.MuiTableContainer-root {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.th-left-borde {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.th-right-borde {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
/* ESTILOS TABLA*/

.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit,
.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot,
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  color: #545454;
  font-family: "Roboto-Regular";
}

.Mui-focused {
  color: #545454 !important;
}

.MuiFormControl-root {
  background-color: transparent !important;
}

/* .MuiOutlinedInput-marginDense:focus {
  border-color: #545454 !important;
} */

/* .MuiOutlinedInput-root:hover fieldset {
  border-color: #1a11d1 !important;
  border-radius: 4px;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #1a11d1 !important;
  border-radius: 4px;
} */

.MuiAccordionSummary-root
  > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 9px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: #1a11d1 !important;
  border-radius: 2px !important;
  padding: 9px;
  height: 36.19px !important;
}

.select-crear.select-modal.e-tarea
  > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: #1a11d1 !important;
  border-radius: 2px !important;
  padding: 9px;
  height: 34px !important;
}

MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.detalle-input {
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 9px;
}

.MuiButtonGroup-contained {
  box-shadow: none !important;
}

.h4-modal {
  font-family: "Exo2-Bold";
  color: #b20000;
  margin-bottom: 1rem;
}

.icon-table {
  border-radius: 10px !important;
}

.modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-header,
.MuiFilledInput-underline:before {
  background-color: transparent;
  border-bottom: none !important;
}

.modal-body {
  background-color: transparent;
}

.modal-footer {
  background-color: transparent;
  border-top: none !important;
}

.modal-footer > * {
  margin: 0px !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.9) !important;
}

.swal2-container {
  z-index: 9900 !important;
}

.MuiButton-root {
  min-width: auto !important;
}

.btn-actividades {
  color: #545454 !important;
  font-family: "Roboto-Regular" !important;
  text-transform: none !important;
  text-transform: initial !important;
}

.white-formlabel .MuiFormControlLabel-label,
.btn-actividades-activo {
  color: #ffffff !important;
  font-family: "Roboto-Regular" !important;
  text-transform: none !important;
  text-transform: initial !important;
}

.btn-filters-map {
  background-color: #bf0811 !important;
  color: #f2f2f2 !important;
  position: absolute;
  z-index: 1;
}

.square-template {
  height: 38px;
  width: 38px;
  border-radius: 7px;
}

.borde-plomo-act {
  border-left: 3px solid #cfcdcd;
  height: 12px;
  top: -12px;
  position: absolute;
  left: 18px;
}

.modal-content {
  border: none !important;
  margin-top: 64px;
  /*border: 1px solid rgba(255, 255, 255, 0.2) !important*/
  background-color: #343434 !important;
  padding: 1rem;
}

.modal-content.modal-white {
  border: none !important;
  margin-top: 64px;
  /*border: 1px solid rgba(255, 255, 255, 0.2) !important*/
  background-color: #ffffff !important;
  padding: 1.5rem;
  border-radius: 15px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.visible {
  display: block !important;
  height: calc(100vh - 64px) !important;
}

.no-visible {
  display: none !important;
}

.select-crear-cliente > .MuiInputBase-input {
  padding: 0 0 0 !important;
  padding-right: 25px !important;
  font-family: "Roboto-Regular" !important;
  font-size: 0.85rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.02857em !important;
  line-height: 1.75 !important;
}

.MuiFilledInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}

.container-select-crear,
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.container-footer-crear {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 56px !important;
}

.container-footer-top {
  padding-top: 14px;
  padding-bottom: 0px;
  min-height: 50px !important;
}

.container-footer-y {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 60px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0 !important;
}

/* MEDIA QUERY */
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 85% !important;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 94% !important;
    margin-right: 3% !important;
    margin-left: 3% !important;
  }
}

@media (min-width: 700px) and (max-width: 1500px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 94% !important;
    margin-right: 3% !important;
    margin-left: 3% !important;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #990000; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.modal {
  z-index: 1301 !important;
}

.select-crear.select-modal.m-estados > .MuiInputBase-input {
  display: flex !important;
  box-sizing: inherit !important;
}

.select-crear.select-modal.m-estados.t-editar {
  background-color: #ffffff !important;
  border-radius: 5px !important;
}

.z-i-300 {
  z-index: 1201 !important;
  position: fixed !important;
}

.w-90 {
  width: 90% !important;
}

.br-5 {
  border-radius: 5px;
}

.p-25 {
  padding: 0.625rem !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.form-control:disabled,
.form-control[readonly] {
  border: none !important;
  color: #000000;
}

.MuiAutocomplete-input {
  margin-top: 3px !important;
}

.MuiAutocomplete-input::-webkit-input-placeholder {
  color: #9f9f9f !important;
  opacity: 1; /* Firefox */
}

.MuiAutocomplete-input:-ms-input-placeholder {
  color: #9f9f9f !important;
  opacity: 1; /* Firefox */
}

.MuiAutocomplete-input::placeholder {
  color: #9f9f9f !important;
  opacity: 1; /* Firefox */
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: transparent !important;
}

.MuiAutocomplete-option[aria-selected="true"]:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.borde-red {
  border: 2px solid #b20000 !important;
}

.MuiSelect-icon {
  top: auto !important;
}

.select-crear.select-estados > .MuiFilledInput-input {
  display: flex !important;
  flex-wrap: wrap !important;
  padding-left: 0px !important;
}

.MuiFormControlLabel-label {
  font-family: "Roboto-Regular" !important;
  color: #545454 !important;
}

.modal-csv p {
  font-family: "Roboto-Regular" !important;
  color: #545454 !important;
}

.link-style {
  font-family: "Roboto-Regular" !important;
  text-decoration: underline !important;
  color: #1a11d1 !important;
  cursor: pointer;
}

.link-style-white {
  text-decoration: underline !important;
  color: #ffffff !important;
  cursor: pointer;
}

.accordionTareas .MuiAccordionSummary-content {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.MuiAccordionSummary-root.accordionTareas
  > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  padding: 7px !important;
}

.MuiTab-root {
  padding: 6px 0px !important;
  max-width: 1000px !important;
}

.href-style {
  text-decoration: underline;
  color: #1a11d1;
  cursor: pointer;
}

.input-km .form-control {
  border: none !important;
  background-color: #f2f2f2 !important;
}

.input-km .input-group-text {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
}

.form-control {
  color: #000000 !important;
}

.MuiAutocomplete-popper {
  z-index: 1303 !important;
}

.MuiSelect-iconFilled,
.r-0 {
  right: 0px !important;
}

.ver-copiado {
  position: absolute;
  bottom: 1px;
  width: calc(100% - 28px);
  font-size: 11px !important;
  line-height: 1.2 !important;
}

.ver-copiado-gt {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10px;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  z-index: 99;
}

.ver-copiado-gt > label {
  margin-bottom: 0px;
}

.ver-copiado-rayo {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 10px;
  line-height: 1.2;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  z-index: 99;
}

.ver-copiado-rayo > label {
  margin-bottom: 0px;
}

.ver-copiado-rayo.hidden,
.ver-copiado-gt.hidden,
.ver-copiado.hidden {
  display: none !important;
}

.Layout-content-8 {
  flex-grow: 1 !important;
}

.Layout-toolbar-7 {
  display: flex;
  padding: 0px 8px;
  min-height: 64px;
  align-items: center;
  justify-content: flex-end;
}

.border-blue-btn {
  background-color: transparent !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border: 2px solid #1a11d1 !important;
  height: 40px !important;
  padding: 10px !important;
  color: #212529 !important;
}

.border-blue-btn:focus {
  border: 2px solid #1a11d1 !important;
}

.border-blue-btn .MuiButton-label {
  color: #212529 !important;
  font-size: 1rem !important;
}

.border-blue-icon {
  height: 40px !important;
}

.contain-template {
  height: 50px !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.w-90-button {
  width: 90px !important;
}

.MuiFormControl-root.form-height > .MuiInputBase-root {
  height: 36.19px !important;
  font-size: 0.85rem !important;
}

.MuiFormControl-root.form-height .MuiInputBase-input,
.MuiFormControl-root.form-height .MuiSelect-outlined.MuiSelect-outlined {
  font-size: 0.85rem !important;
}

.MuiSelect-selectMenu.MuiTablePagination-select {
  font-size: 0.8rem !important;
}

.select-modal .MuiIconButton-edgeEnd {
  margin-right: 0 !important;
}
#ingresarTemplate .MuiAccordion-rounded {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

#ingresarTemplate .MuiAccordion-rounded:first-child {
  border: none !important;
}

#ingresarTemplate .MuiAccordion-root:before {
  background-color: transparent !important;
}

#ingresarTemplate .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
#ingresarTemplate .MuiFormControlLabel-root {
  margin-bottom: 0px !important;
}

#ingresarTemplate .MuiAccordionDetails-root {
  display: block !important;
}

#ingresarTemplate .MuiFormControlLabel-root.Mui-disabled .MuiSvgIcon-root path {
  fill: #c2c2c2 !important;
}

#ingresarTemplate
  .MuiFormControlLabel-root.Mui-disabled
  .MuiFormControlLabel-label {
  color: #c2c2c2 !important;
}

.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

em {
  color: #c5c5c5;
}

.input-group-text,
.form-control,
.btn {
  font-size: 0.85rem !important;
}

.small-font .MuiTableCell-root {
  font-size: 0.75rem !important;
  line-height: 1 !important;
}

.text-white {
  color: #fff !important;
}

.outline-primary {
  border: 2px solid #1a11d1 !important;
  border-radius: 4px !important;
}

.href {
  text-decoration: underline;
  cursor: pointer;
  /* color: #1a11d1 !important; */
}

.modal {
  overflow-y: auto;
}

.d-desktop-flex {
  display: flex !important;
}

.d-desktop {
  display: block !important;
}

.d-mobile-flex,
.d-mobile {
  display: none !important;
}

@media (min-width: 0px) and (max-width: 600px) {
  .d-desktop-flex,
  .d-desktop {
    display: none !important;
  }

  .d-mobile {
    display: block !important;
  }

  .d-mobile-flex {
    display: flex !important;
  }
}

.container-calendar {
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 9px rgba(0, 0, 0, 0.22);
}

.container-calendar > p {
    font-size: 9px !important;
    color: #242422 !important;
    font-family: 'Roboto-Regular' !important;
    text-align: center !important;
    margin-bottom: 0px !important;
}

.DayPicker-wrapper {
    padding-bottom: 0px !important;
}

.DayPicker-wrapper:focus,
.DayPicker-Day:focus,
.DayPicker-NavButton:focus {
    outline: none !important;
}

.DayPicker-Caption {
    margin-bottom: 0px !important;
    font-family: 'Roboto-Regular' !important;
    color: #242422 !important;
    text-align: center !important;
}

.DayPicker-Month {
    border-collapse: separate !important;
    border-spacing: 8px 5px !important;
    margin-top: 2px !important;
}

.DayPicker-Weekday {
    height: 19px !important;
    max-height: 19px !important;
    width: 21px !important;
    max-width: 21px !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 9px !important;
    color: #242422 !important;
}

.DayPicker-Day {
    background-color: #e3e3e3 !important;
    height: 19px !important;
    max-height: 19px !important;
    width: 21px !important;
    max-width: 21px !important;
    border-radius: 5px !important;
    text-align: center !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 9px !important;
    color: #242422 !important;
}
.DayPicker-Day.DayPicker-Day--today {
    font-family: 'Exo2-Bold' !important;
    color: #000 !important;
}

.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected,
.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected {
    background-color: #4545f4 !important;
    color: #ffffff !important;
}

.DayPicker-Day.DayPicker-Day--selected {
    background-color: rgb(69, 69, 244, 0.5) !important;
    color: #ffffff !important;
}

.DayPicker-Day:hover {
    background-color: #d9d9d9 !important;
    cursor: pointer !important;
}

.DayPicker-Day.DayPicker-Day--selected:hover {
    background-color: #1a11d1 !important;
    color: #ffffff !important;
}

.DayPicker-Day.DayPicker-Day--outside {
    background-color: #f2f2f2 !important;
}

.DayPicker-NavButton {
    top: 0.2rem !important;
}

.DayPicker-NavButton--prev {
    left: 20px !important;
}

.DayPicker-NavButton--next {
    right: 20px !important;
}

.container-horas {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Roboto-Regular' !important;
    color: #242422;
}

.container-horas > .col-2 > .imagen-titulo {
    height: 28px;
    width: 28px;
    border-radius: 5px;
}

.MuiSlider-rail {
    width: calc(100% - 2px) !important;
    height: 4px !important;
    border-radius: 2px !important;
    background-color: transparent !important;
    border: 1px solid #242422 !important;
}

.MuiSlider-track {
    height: 6px !important;
    background-color: #242422 !important;
}

.MuiSlider-thumb {
    margin-top: -3px !important;
    height: 12px !important;
    width: 12px !important;
    background-color: #1a11d1 !important;
}

.MuiSlider-valueLabel > span {
    font-family: 'Roboto-Regular' !important;
    font-size: 9px !important;
    background-color: #1a11d1 !important;
}

.dropdown-menu.calendario-drop {
    left: auto !important;
    margin: 0.125rem 0.4rem 0 !important;
}

.button-filtrar {
    font-family: 'Roboto-Regular' !important;
    font-size: 12px !important;
    background-color: transparent !important;
    color: #4545f4 !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    margin-bottom: 10px !important;
}

.button-filtrar:hover {
    background-color: #4545f4 !important;
    color: #ffffff !important;
}

.menu-logo {
    width: 150px;
}

@media (min-width: 600px) {
    .MuiToolbar-gutters {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.MuiButtonGroup-contained {
    max-height: 30px !important;
}

.MuiToolbar-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
    border-right: none !important;
}

.btn-navbar {
    font-family: 'Roboto-Regular' !important;
    /* color: #ffffff !important; */
    font-size: 10px !important;
    height: 30px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    background-color: #242422 !important;
}

.MuiDrawer-paperAnchorDockedRight {
    background-color: #242422 !important;
}

/* .MuiSvgIcon-root {
    color: #f2f2f2;
} */

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    align-items: center !important;
    justify-self: center !important;
}

.MuiListItemIcon-root {
    min-width: 0px !important;
    display: inline !important;
}

.boton-actualizar .MuiButton-startIcon {
    display: block !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;
}

.circle-user {
    border-radius: 35px;
    height: 67px;
    width: 67px;
}

.icon-user {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    width: 70px;
    height: auto;
}

.icon-user-right {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    width: 70px;
    height: auto;
}

.icon-other {
    width: 35px;
    height: auto;
}

.back-icon {
    background-color: #545454;
}

.Layout-drawerClose-8 {
    width: 100px !important;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight,
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    margin-top: 64px !important;
    background-color: #242422;
}

.text-layout {
    font-family: 'Roboto-Regular' !important;
    color: white;
    font-size: medium;
}

.MuiIcon-colorSecondary {
    color: white;
    background-color: white;
}

.MuiAccordion-root > .MuiCollapse-container.MuiCollapse-entered {
    margin-left: 0px;
    border-left: none;
}

.MuiCollapse-container.MuiCollapse-entered {
    margin-left: 30px;
    border-left: 5px solid #4545f4;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

.sub-admin {
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 3px;
    left: 5px;
    top: 18px;
}

.icon-arrow {
    width: 20px;
}

.MuiToolbar-regular {
    max-height: 64px;
}

@media (min-width: 0px) and (max-width: 770px) {
    .Layout-drawerClose-8 {
        width: 57px !important;
    }

    .circle-user {
        border-radius: 20px;
        margin-left: -5px;
        height: 37px;
        width: 37px;
    }

    .icon-user {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        width: 50px;
        height: auto;
    }

    .icon-user-right {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        width: 50px;
        height: auto;
    }

    .icon-other {
        margin-left: -5px;
        width: 40px;
        height: auto;
    }
}

.btn-drop-icon {
    border-radius: 4px !important;
    right: 0px !important;
}

.Layout-sectionDesktop-12 button {
    font-size: 10px !important;
}

.Layout-sectionDesktop-12 {
    height: auto !important;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.9) !important;
    margin-top: 64px !important;
}

.selected-list {
    background-color: #b20000 !important;
    border-radius: 5px !important;
}

.px-2-5 {
    padding-left: 0.6rem !important;
    padding-right: 0.5rem !important;
}

.pl-2-5 {
    padding-left: 0.7rem !important;
}

.select-cliente {
    height: 30px !important;
    font-size: 10px !important;
    background-color: #4545f4 !important;
}

.select-cliente > button {
    height: 30px !important;
}

.select-cliente > .MuiFilledInput-input {
    padding: 0px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    background-color: transparent !important;
}

.select-cliente > svg {
    display: none !important;
}

.input-layout:disabled {
    border-radius: 15px !important;
    background-color: #e6e6e6 !important;
}

.input-layout {
    border-radius: 15px !important;
    background-color: #f2f2f2 !important;
}

.mw-115 {
    min-width: 115px !important;
}

.mw-140 {
    min-width: 150px !important;
}

.opt-layout {
    font-size: 10px !important;
}

.select-cliente .img-layout {
    display: block !important;
}

.MuiMenu-list .img-layout {
    display: none !important;
}

.MuiMenu-list .opt-layout {
    font-size: 12px !important;
}

.MuiListItem-button:hover span {
    font-family: 'Roboto-Bold' !important;
}

.MuiAutocomplete-option {
    color: #545454 !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 0.75rem !important;
}

.MuiAutocomplete-option-title {
    color: #000000 !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 15px !important;
}

.MuiAutocomplete-option-subtitle {
    color: #545454 !important;
    font-family: 'Roboto-Regular' !important;
    font-size: 13px !important;
}

.MuiAutocomplete-paper {
    margin: auto !important;
}

.btn-left-calendar.MuiButton-root {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.btn-right-calendar.MuiButton-root {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.container-cargando {
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: fixed !important;

    z-index: 214748364 !important;
    -webkit-transition: width 2s;
    cursor: progress;
}
.container-gif {
    min-height: 100vh;
    min-width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);
}

.oculto {
    display: none;
}

.gif {
    background-color: transparent;
    opacity: 1;
}

.label-login {
  font-family: "Roboto-Regular" !important;
  color: #343434;
}

.background-login {
  background-image: url(/static/media/imagen_login.36134637.png);
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100vh;
}

.contenedor-login {
  max-width: 500px;
  background-color: #f2f2f2;
}

.form-login {
  margin-bottom: 10%;
}

.input-login {
  border: 1px solid #545454;
  border-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
}

.input-login-password,
.input-login-password:focus {
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-left: 1px solid #545454 !important;
  border-right: none !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
}

.input-login-button {
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-right: 1px solid #545454 !important;
  border-left: none;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
  padding: 0.32rem 0.75rem !important;
}

.form-control-login:-webkit-autofill,
.form-control-login:-webkit-autofill:hover,
.form-control-login:-webkit-autofill:focus,
.form-control-login:-webkit-autofill:active .input-login:-webkit-autofill,
.input-login:-webkit-autofill:hover,
.input-login:-webkit-autofill:focus,
.input-login:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  border: 1px solid #545454 !important;
  font-family: "Roboto-Regular" !important;
}

.input-login-password:-webkit-autofill,
.input-login-password:-webkit-autofill:hover,
.input-login-password:-webkit-autofill:focus,
.input-login-password:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-left: 1px solid #545454 !important;
  border-right: none !important;
  font-family: "Roboto-Regular" !important;
}

.input-login-button:hover,
.input-login-button:focus {
  border-top: 1px solid #545454 !important;
  border-bottom: 1px solid #545454 !important;
  border-right: 1px solid #545454 !important;
  border-left: none;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  font-family: "Roboto-Regular";
  background-color: #f2f2f2 !important;
}

.input-login::-webkit-input-placeholder {
  color: #9f9f9f !important;
}

.input-login:-ms-input-placeholder {
  color: #9f9f9f !important;
}

.input-login::placeholder {
  color: #9f9f9f !important;
}

.input-login:-ms-input-placeholder {
  color: #9f9f9f !important;
}

.input-login::-ms-input-placeholder {
  color: #9f9f9f !important;
}

.input-login-icon {
  width: 22px !important;
}

.input-login-icon-closed {
  width: 18px !important;
  margin-right: 2px !important;
}

.div-login {
  background-color: #f2f2f2;
  max-width: 500px;
  border-radius: 20px;
}

.btn-login {
  background-color: #858585;
  color: #f2f2f2;
  font-family: "Roboto-Regular";
  font-size: 0.85rem;
  font-weight: 400;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-login:hover {
  background-color: #311def;
  color: #f2f2f2;
}

.btn-login:active {
  background-color: #311def;
  color: #f2f2f2;
}

.sin-espacios {
  height: 0px !important;
}

.input-group-append.login {
  background-color: transparent !important;
}

.img-login {
  width: 100%;
  max-width: 250px;
}

.home-screen {
  height: calc(100vh - 64px) !important;
  min-height: calc(100vh - 64px) !important;
}

.img-rayo {
  border-radius: 5px;
}

.map {
  height: calc(100vh - 64px);
  border: none !important;
  width: 100%;
}

.cargando-paneles {
  height: calc(100vh - 64px - 99px - 40px);
}

.cargando-paneles-rayo {
  height: calc(100vh - 64px - 99px) !important;
  max-height: calc(100vh - 64px - 99px) !important;
  overflow: auto !important;
}

.container-info-gt {
  height: 82px !important;
  overflow: auto !important;
}

.container-info-gt::-webkit-scrollbar {
  width: 0px !important;
}

.paper-total {
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto !important;
  background-color: #fff !important;
}

.paper {
  max-height: calc(100vh - 64px - 99px);
  min-height: calc(100vh - 64px - 99px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-perfil {
  height: calc(100vh - 64px - 86px);
  overflow: auto !important;
}

.paper-rayos-tasks {
  height: calc(100vh - 64px - 89px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-tarea {
  height: calc(100vh - 64px - 219px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-mini {
  height: calc(100vh - 64px - 99px - 54px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-editar {
  height: calc(100vh - 64px - 51px - 60px - 56px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-middle {
  height: calc(100vh - 38px - 64px - 54px);
  overflow: auto !important;
  background-color: #fff !important;
}

.hide-navbar {
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  display: flex;
  z-index: 1300;
  width: 100%;
}

.plomo {
  background-color: #fff !important;
}

.div-black {
  background-color: #4545f4;
}

.div-gray {
  background-color: #545454;
}

input.input-sup,
input.input-sup:-webkit-autofill,
input.input-sup:-webkit-autofill:hover,
input.input-sup:-webkit-autofill:focus,
input.input-sup:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #e5e5e5 inset !important;
  background-color: #e5e5e5 !important;
  border: none;
  color: #343434;
  font-size: 25px;
}

.input-sup:focus,
.input-sup:focus {
  outline: none;
}

.button-inf {
  color: #f2f2f2 !important;
}

.button-inf:hover {
  background-color: #242422 !important;
}

.button-inf:active {
  background-color: #bf0811 !important;
}

.card-task {
  width: 90%;
}

.card-rayo {
  width: 95% !important;
  box-shadow: none !important;
}

.button-state {
  color: #f2f2f2 !important;
  border-radius: 10% !important;
  font-size: 12px !important;
}

.button-number-task:disabled,
.button-number-task[disabled],
.button-number-task {
  height: 30px;
  width: 30px;
  font-family: "Exo-Bold" !important;
  margin-bottom: 4px !important;
}

.button-state:disabled,
.button-state[disabled] {
  color: #f2f2f2 !important;
  border-radius: 0.4rem !important;
  font-size: 12px !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.MuiCardContent-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.MuiCheckbox-root {
  padding: 0.5rem !important;
}

.carta-tarea {
  border-radius: 5px;
  height: 140px;
  overflow: auto !important;
}

.orden-name {
  height: 40px;
  z-index: 2;
}

.carta-orden {
  border-radius: 5px;
  border: 2px solid #9999ff;
}

.carta-orden-red {
  border-radius: 5px;
  border: 2px solid rgb(220, 9, 20);
}

.carta-orden-green {
  border-radius: 5px;
  border: 2px solid #28bd27;
}

.carta-orden-estado {
  border-radius: 8px;
  border: 2px solid #9999ff;
  background-color: #fff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-red {
  border-radius: 8px;
  border: 2px solid rgb(220, 9, 20);
  background-color: #fff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-green {
  border-radius: 8px;
  border: 2px solid #28bd27;
  background-color: #fff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-historial {
  border-radius: 8px;
  border: 2px solid #9999ff;
  background-color: #ffffff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 12px;
  width: 180px;
}

.carta-orden-estado-saltada {
  border-radius: 8px;
  border: 2px solid #707070;
  background-color: #ffffff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-gt {
  background-color: #ffffff;
  font-family: "Roboto-Regular";
  border-radius: 5px;
  height: 30px !important;
}

.btn-rayo.MuiButton-text {
  padding: 6px !important;
}

.min-txt-carta {
  font-size: 6px;
  line-height: 1.2;
}

.sub-txt-carta {
  color: #545454;
  font-size: 11px;
  line-height: 1.2;
}

.txt-carta {
  font-size: 11px !important;
  line-height: 1.2 !important;
}

.txt-carta-name {
  font-size: 12px;
  line-height: 1.2;
}

.txt-carta-medium {
  font-size: 10px;
  line-height: 1.2;
}

.txt-carta-small {
  font-size: 9px;
  line-height: 1.2;
}

.txt-rayo-name {
  font-size: 13px;
  line-height: 1.2;
}

.rotar-icono {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.rotar-icono-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.rotar-icono-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media (min-width: 600px) {
  .MuiTab-root {
    padding: 6px 0px !important;
    min-width: auto !important;
  }
}

.tab-task {
  width: 50% !important;
  font-family: "Exo2-Regular" !important;
  color: #ffffff !important;
  background-color: #a6a6a6 !important;
}

.tab-task.Mui-selected {
  width: 50% !important;
  font-family: "Exo2-Bold" !important;
  color: #5c5c57 !important;
  background-color: #efefef !important;
}

.tab-rayos {
  /* width: 33.33333333333333333333333% !important; */
  width: 50% !important;
  font-family: "Exo2-Regular" !important;
  color: #ffffff !important;
  background-color: #a6a6a6 !important;
}

.tab-rayos.Mui-selected {
  /* width: 33.33333333333333333333333% !important; */
  width: 50% !important;
  font-family: "Exo2-Bold" !important;
  color: #5c5c57 !important;
  background-color: #efefef !important;
}

.tab-rayos-home {
  width: 33.33333333333333333333333% !important;
  /* width: 50% !important; */
  font-family: "Exo2-Regular" !important;
  color: #ffffff !important;
  background-color: #a6a6a6 !important;
}

.tab-rayos-home.Mui-selected {
  width: 33.33333333333333333333333% !important;
  /* width: 50% !important; */
  font-family: "Exo2-Bold" !important;
  color: #5c5c57 !important;
  background-color: #efefef !important;
}

.tab-detalle {
  width: 50% !important;
  font-family: "Roboto-Regular" !important;
  color: #ffffff !important;
  background-color: #9999ff !important;
}

.tab-detalle.MuiTab-textColorInherit.Mui-selected {
  background-color: #1a11d1 !important;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.task-type-line {
  height: 100%;
  border-left: 2px solid #9999ff;
  margin-left: 7px;
}

.task-type-dot {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  position: absolute;
  font-family: "Roboto-Regular" !important;
  color: #ffffff !important;
  font-size: 12px;
}

.task-type-little-dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #9999ff;
  position: absolute;
  bottom: 0px;
  left: 4px;
}

.dot-orden-list {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  z-index: 300;
  position: absolute;
}

.borde-orden-list {
  position: absolute;
  height: 130px;
  left: 7px;
  top: -90px;
}

.borde-orden-list-historial {
  position: absolute;
  height: 60px;
  border-left: 2px solid #9999ff;
  left: 7px;
  top: -40px;
}

.borde-orden-list-1 {
  position: absolute;
  height: 70%;
  left: 7px;
  top: -38px;
}

.borde-orden-list-x {
  position: absolute;
  border-top: 2px solid #9999ff;
  top: -38px;
  width: 350%;
  bottom: 75%;
  left: 7px;
}

.alto-dark {
  height: 51px;
}

.alto-grey {
  height: 48px;
}

.bottom-fix {
  width: 100%;
  bottom: 0px;
  padding-top: 11px;
  padding-bottom: 11px;
  height: 54px;
  background-color: #fff;
}

.MuiTableCell-root {
  padding: 14px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiTableCell-root.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.MuiTableCell-root.mini-table-th {
  padding: 2px !important;
  font-size: 11px;
  background-color: #9f9f9f;
}

.MuiTableCell-root.mini-table-td {
  font-size: 11px;
  text-align: center !important;
  background-color: #cfcdcd;
}

.mini-table-thead tr {
  height: auto !important;
}

table.mini-table {
  border-collapse: separate !important;
  border-spacing: 0 0px !important;
}

.dot-tipo-tarea {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.MuiInput-underline:after {
  border: none !important;
}

.MuiInputBase-input {
  border-radius: 10px !important;
  font-size: 0.85rem !important;
}

input[type="datetime-local"].MuiInputBase-input {
  padding: 10px 5px 0px !important;
}

.MuiFormControl-root.MuiTextField-root.form-control {
  border-radius: 0.25rem;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.opc-drop {
  height: 37px;
  width: 200px;
  background-color: #fff;
  font-family: "Roboto-Regular" !important;
  border-radius: 2px;
}

.opc-drop-gt {
  height: 35px;
  width: 220px;
  background-color: #fff;
  font-family: "Roboto-Regular" !important;
  border-radius: 2px;
}

.opc-drop-gt:hover,
.opc-drop:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.dropdown-menu {
  border: none !important;
  background-color: transparent !important;
}

.border-bottom-0 {
  border-top: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  border-bottom: none !important;
}

.select-crear > .MuiFilledInput-input {
  padding: 10px;
}

.modal-body > .MuiFormControl-root > .select-crear > .MuiIconButton-edgeEnd,
.modal-body
  > row
  > col-8
  > .MuiFormControl-root
  > .select-crear
  > .MuiIconButton-edgeEnd {
  margin-right: 0px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.modal-body > .MuiFormControl-root > .select-crear,
.select-crear.select-modal {
  background-color: transparent !important;
  border-radius: 4px !important;
  border: 2px solid #1a11d1 !important;
  height: 40px;
}

.select-crear.select-modal.e-tarea {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border: 2px solid #1a11d1 !important;
  height: 34px;
}

.MuiPopover-root {
  z-index: 1301 !important;
}
/* .MuiPopover-root {
    margin-top: 0px !important;
    left: -16px !important;
} */
div.form-control.txt-modal,
input.form-control.txt-modal {
  border: 2px solid #1a11d1 !important;
  height: 40px;
  border-radius: 4px !important;
  background-color: transparent !important;
}

.vista-lista > .MuiTableHead-root > tr > th {
  font-size: 12px !important;
  line-height: 1.2 !important;
}

.vista-lista > .MuiTableBody-root > tr > td {
  font-size: 12px !important;
  line-height: 1.2 !important;
}

.col-65 {
  flex: 0 0 58.3333333333333%;
  max-width: 58.3333333333333%;
  position: relative;
}

.col-05 {
  flex: 0 0 4.1666666666666%;
  max-width: 4.1666666666666%;
  position: relative;
}

.address-task-foot {
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.panel-detalle {
  width: 380px !important;
  min-width: 380px !important;
  min-height: 100vh !important;
  background-color: #fff !important;
}

.autocomplete-rayo .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.autocomplete-rayo
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 8px 4px 11px !important;
}

.autocomplete-layout-rayos {
  border: none !important;
  height: 30px !important;
  border-radius: 4px;
  min-width: 152px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores.autocomplete-layout-clientes {
  border: none !important;
  height: 30px !important;
  border-radius: 4px;
  min-width: 160px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores {
  background-color: #4545f4;
  border: none !important;
  height: 30px !important;
  border-radius: 4px;
  min-width: 180px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores input::-webkit-input-placeholder, .autocomplete-layout-rayos input::-webkit-input-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
  font-family: "Roboto-Regular", FontAwesome !important;
}

.autocomplete-layout-proveedores input:-ms-input-placeholder, .autocomplete-layout-rayos input:-ms-input-placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
  font-family: "Roboto-Regular", FontAwesome !important;
}

.autocomplete-layout-proveedores input::placeholder,
.autocomplete-layout-rayos input::placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
  font-family: "Roboto-Regular", FontAwesome !important;
}

.autocomplete-layout-proveedores .MuiOutlinedInput-root fieldset,
.autocomplete-layout-rayos .MuiOutlinedInput-root fieldset {
  border: none !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.autocomplete-layout-rayos
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
  padding-left: 5px !important;
  padding-right: 45px !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input,
.autocomplete-layout-rayos
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 3px 4px 5px !important;
  font-family: "Roboto-Regular" !important;
  color: #ffffff !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-popupIndicator
  .MuiSvgIcon-root,
.autocomplete-layout-rayos .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  fill: #ffffff !important;
}

.autocomplete-layout-proveedores .MuiAutocomplete-popupIndicator,
.autocomplete-layout-rayos .MuiAutocomplete-popupIndicator {
  /* background-color: #1a11d1 !important; */
  margin-right: -10px !important;
  border-radius: 4px !important;
  height: 30px !important;
  top: -2px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-clearIndicatorDirty
  .MuiSvgIcon-root,
.autocomplete-layout-rayos
  .MuiAutocomplete-clearIndicatorDirty
  .MuiSvgIcon-root {
  fill: #ffffff !important;
}

.autocomplete-layout-proveedores .MuiAutocomplete-clearIndicatorDirty,
.autocomplete-layout-rayos .MuiAutocomplete-clearIndicatorDirty {
  top: -2px !important;
}

.end-start-icon .MuiButton-startIcon {
  position: absolute !important;
  left: 10px !important;
}

.end-start-icon .MuiButton-endIcon {
  position: absolute !important;
  right: 15px !important;
}

.task-form .react-tel-input .form-control:-webkit-autofill,
.task-form .react-tel-input .form-control:-webkit-autofill:focus,
.task-form .react-tel-input .form-control:-webkit-autofill:active,
.task-form .react-tel-input .form-control:-webkit-autofill:hover,
.task-form .react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
  background: #dad8d8 !important;
  -webkit-box-shadow: 0 0 0 30px #dad8d8 inset !important;
  background-color: #dad8d8 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 1rem !important;
  font-family: "Roboto-Regular" !important;
}

.task-form .react-tel-input .flag-dropdown {
  border: none !important;
  background-color: #dad8d8 !important;
  border-radius: 10px 0 0 10px !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.task-form .react-tel-input .selected-flag {
  border: none !important;
  border-radius: 10px 0 0 10px !important;
  background-color: #dad8d8 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.react-tel-input .form-control:-webkit-autofill,
.react-tel-input .form-control:-webkit-autofill:focus,
.react-tel-input .form-control:-webkit-autofill:active,
.react-tel-input .form-control:-webkit-autofill:hover,
.react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
  background: #f2f2f2 !important;
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  background-color: #f2f2f2 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 1rem !important;
  font-family: "Roboto-Regular" !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background-color: #f2f2f2 !important;
  border-radius: 10px 0 0 10px !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.react-tel-input .selected-flag {
  border: none !important;
  border-radius: 10px 0 0 10px !important;
  background-color: #f2f2f2 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.react-tel-input .country-list {
  width: 315px !important;
  margin-top: 0px !important;
  font-family: "Roboto-Regular" !important;
}

.react-tel-input .country-list .flag {
  margin-right: 14px !important;
}

.py-05 {
  padding-bottom: 0.15rem !important;
}
.py-05 {
  padding-top: 0.15rem !important;
}

.px-05 {
  padding-left: 0.17rem !important;
}
.px-05 {
  padding-right: 0.17rem !important;
}
.f-right {
  right: 0px !important;
  margin-right: 0.17rem !important;
}

.scuare-light-blue {
  background-color: #9999ff;
  height: 30px;
  width: 30px;
  border-radius: 5px;
}
.checkbox-rayo > .MuiTypography-body1 {
  font-size: 9px !important;
  color: #545454;
  line-height: 1.2;
}

.letra-task {
  background-color: #1a11d1;
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  bottom: -6px;
  left: -6px;
  color: #ffffff;
  font-family: "Exo2-Bold";
  border: 2px solid #ffffff;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  display: none !important;
}

#content-mapa {
  background-color: #ffffff;
  padding: 0.6rem;
  border-radius: 10px;
  min-width: 210px;
}
.my-div-icon {
  height: 10px;
  width: 10px;
  background-color: #1a11d1;
}

.circle {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background-color: #1a11d1;
}

.circle-test {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 14px;
}

.circle-test,
.circle p {
  margin-bottom: 3px;
  font-size: 11pt;
  color: #ffffff;
  font-family: "Exo2-Bold";
  z-index: 500;
}

.triangle {
  position: absolute;
  top: 16px;
  width: 0;
  height: 0;
  border-radius: 20px;
  border-style: solid;
  border-width: 28px 14px 0 14px;
  border-color: #1a11d1 transparent transparent transparent;
  z-index: 0;
}

.triangle-test {
  position: absolute;
  top: 16px;
  width: 0;
  height: 0;
  border-radius: 20px;
  border-style: solid;
  border-width: 28px 14px 0 14px;
  z-index: 0;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

#content-mapa {
  background-color: #ffffff;
  padding: 0.6rem;
  border-radius: 10px;
  min-width: 210px;
}

#content-mapa .cerrar {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  cursor: pointer;
}

#info-box-optimizado,
#info-box-optimizar {
  width: 100%;
  background-color: #efefef;
  padding: 5px 0px;
  position: absolute;
  top: 99px;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/*----------- TARGETA GT LISTA VISTA MAPA ------------*/
.tarjeta-gt-box {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.tarjeta-gt {
  /* background-color: #e4e9fa; */
  border-radius: 5px;
  width: 100%;
  height: 175px;
}

.tarjeta-gt .check-box {
  width: 36px;
}

.tarjeta-gt .rayo-box {
  width: 40px;
}

.rayo-box img {
  border-radius: 5px;
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
}

.txt-small {
  text-align: center;
  color: #545454;
  font-size: 8.5px;
  line-height: 1.2;
  margin-bottom: 0px;
}

.tarjeta-gt .type-task-box {
  width: 25px;
}

.dot-type-task {
  position: absolute;
  top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  font-size: 10px;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fff;
}

.dot-type-task-1 {
  position: absolute;
  top: 50px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #fff;
}

.dot-type-task-2 {
  position: absolute;
  bottom: 60px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #fff;
}

.dot-type-task-bottom {
  position: absolute;
  bottom: 55px;
  margin-left: 9.5px;
  margin-right: 9.5px;
  height: 6px;
  width: 6px;
  border-radius: 5px;
  background-color: #9999ff;
}

.line-type-task {
  height: 70px;
  position: absolute;
  top: 50px;
  margin-left: 11.5px;
}

.line-type-tasks {
  height: 60px;
  position: absolute;
  top: 55px;
  margin-left: 11.5px;
}

.line-type-more-tasks {
  height: 25px;
  position: absolute;
  top: 120px;
  margin-left: 11.5px;
}

.tarjeta-gt .info-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.tarjeta-gt .info-box p {
  font-size: 11px;
  line-height: 1.2;
  margin-bottom: 0px;
}

.tarjeta-gt .atrasado,
.tarjeta-gt .en-riesgo {
  position: absolute;
  bottom: 5px;
  right: 10px;
  margin-top: 3px;
  border-radius: 3px;
  height: 22px;
  font-size: 10px;
  line-height: 1.4;
  padding: 4px;
}

.tarjeta-gt .atrasado {
  background-color: rgba(250, 133, 138, 0.6);
  color: #c40812;
}

.tarjeta-gt .atrasado img,
.tarjeta-gt .en-riesgo img {
  height: 13px;
  width: 13px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.tarjeta-gt .en-riesgo {
  background-color: rgba(252, 213, 131, 0.8);
  color: #c78a05;
}

.tarjeta-gt .info-box .info-foot {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  bottom: 10px;
  color: #9f9f9f !important;
}

.tarjeta-gt .btn-estado {
  font-size: 10px;
  line-height: 1.2;
  color: #fff;
  position: absolute;
  padding: 5.5px;
  border-radius: 3px;
  text-align: center;
  top: 10px;
  right: 10px;
}

.tarjeta-gt .completed-box {
  position: absolute;
  top: 36px;
  right: 10px;
  font-size: 7.5px;
  line-height: 1.2;
  color: #545454;
}

.tarjeta-gt .detalle-box {
  width: 17px;
}

.tarjeta-gt .detalle-box img {
  width: 10px;
  height: 20px;
  cursor: pointer;
}

.order-id-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  font-family: "Roboto-Bold";
  top: 12px;
}

.customer-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  bottom: 10px;
  color: #9f9f9f !important;
  font-family: "Roboto-Bold";
}

.mapa-google {
  height: 100%;
  width: 100%;
}

.bg-waring {
  background-color: #c78a05 !important;
}

.select-filter-estado:focus,
.select-filter-estado {
  height: 36.19px !important;
  border: 2px solid #1a11d1 !important;
  background-color: #ffffff;
  text-transform: capitalize !important;
}

.select-filter-estado.MuiButton-text {
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.select-filter-estado .MuiButton-endIcon {
  padding: 6px;
  margin-right: 0px !important;
  background-color: #1a11d1;
}

.select-search-options fieldset span {
  display: none !important;
}

.MuiOutlinedInput-root.Mui-focused.select-search-options .MuiOutlinedInput-notchedOutline,
.select-search-options fieldset {
  border: none !important;
  border-top: 2px solid #1a11d1 !important;
  border-bottom: 2px solid #1a11d1 !important;
  border-left: none !important;
  border-radius: 0px !important;
}

.MuiOutlinedInput-root.Mui-focused.input-search-options .MuiOutlinedInput-notchedOutline,
.input-search-options fieldset {
  border-top: 2px solid #1a11d1 !important;
  border-bottom: 2px solid #1a11d1 !important;
  border-left: 2px solid #1a11d1 !important;
  border-right: 1px solid #9f9f9f !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-search-options.MuiButton-root {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 9px !important;
  box-shadow: none !important;
}

.text-white .MuiFormControlLabel-label {
  color: #fff !important;
}

.switch-white .MuiSwitch-track {
  background-color: #f2f2f2 !important;
}

.btn-csv .MuiIconButton-edgeEnd {
  margin-right: 0px !important;
}

